<!-- 模板 -->
<template>
	<div class="">
		<div class="contant">
			<headers class="top" :active="1"></headers>
			<!-- <bananer :active="1"></bananer> -->
			<div class="header1">
				<div class="he1_content">
					<div class="left_div">
						<h3>行业大师证书一键生成</h3>
						<div class="left_title">
							<input class="ti_input" type="text" placeholder="请输入你的名字" v-model="name" />
							<div class="left_anniu" @click="download()">
								一键生成
								<img src="../assets/image/13.png" alt="" />
							</div>
						</div>
						<div class="left_zhizuo">
							<!-- <span class="zi"
                >今日已生成<span class="cishu">100</span>次，已累计制作<span
                  class="cishu"
                  >100000</span
                >次</span
              > -->
						</div>
						<!-- <div class="demo-image__preview">
              <el-image
                style="width: 100px; height: 100px"
                :src="url"
                :preview-src-list="srcList"
              >
              </el-image>
            </div> -->
					
						
						<div class="left_jilu">
							<div class="jilu_title">生成记录：</div>
							<div class="jilu_flex">
								<div class="jilu_flexs">
									<div class="" style="padding: 10px;" v-for="(v, i) in imgurl" :key="i">
										<!-- <img class="j_img" :src="v" alt="" /> -->
										<el-image :z-index='100000' class="j_img" :src="v" :preview-src-list="imgurl">
										</el-image>
									</div>
								</div>
							</div>
							<!-- <div class="jilu_flex">
                <img
                  class="j_img"
                  src="../assets/image/zhengshu.png"
                  alt=""
                  v-for="(v, i) in 3"
                  :key="i"
                />
              </div> -->
						</div>
					</div>
					<div class="right_div">
						<div class="ght_img" ref="content">
							<span class="xingming">{{ name }}</span>
							<span class="xingming_s">{{ user_type }}</span>
							<img class="div_img" :src="$baseUrl + zh_img" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div style="padding-top:40px;">
				<div class="wus teacher">
					<div class="stu a" @click="(art = 0), (user_type = '管理大师')">
						<div v-if="art == 0" class="xuan"></div>
						<div class="gu">管理大师</div>
						<div class="<strong>tit</strong>">管理技术高超</div>
					</div>
					<div class="stu b" @click="(art = 1), (user_type = '营销大师')">
						<div v-if="art == 1" class="xuan"></div>
						<div class="gu">营销大师</div>
						<div class="tit">营销技术高超</div>
					</div>
					<div class="stu c" @click="(art = 2), (user_type = '策划大师')">
						<div v-if="art == 2" class="xuan"></div>
						<div class="gu">策划大师</div>
						<div class="tit">管理技术高超</div>
					</div>
					<div class="stu d" @click="(art = 3), (user_type = '技术大师')">
						<div v-if="art == 3" class="xuan"></div>
						<div class="gu">技术大师</div>
						<div class="tit">管理技术高超</div>
					</div>
				</div>
			</div>
			<div class="certificate">
				<div class="wus book">
					<div class="zu">
						<div class="line"></div>
						<div class="tit">{{ zhn_list.name }}</div>
						<div class="line"></div>
					</div>
					<div class="chi">Certificate display</div>
					<div class="trophy">
						<div class="trophy_l">
							<div @click.stop="pai(it, i)" v-for="(it, i) in zhn_list.content" :key="i"
								:class="current == i ? 'tit tits' : 'tit'">
								{{ it.title }}
							</div>
						</div>
						<div class="trophy_r">
							查看更多<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="books_s">
						<div class="books_s" v-for="(it, i) in zhn_img_list" :key="i">
							<img :src="$baseUrl + it" class="img_a" alt="" />
						</div>
					</div>
				</div>
				<img src="../assets/image/18.png" class="imshan" alt="" />
			</div>
			<!-- <div class="user">
        <div class="wu users">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">用户好评</div>
            <div class="line"></div>
          </div>
          <div class="chi">Certificate display</div>
          <div class="adv">
            <div class="advs" v-for="(it, i) in 2" :key="i">
              <div class="advs_l">
                <img src="../assets/image/zhengshu.png" alt="" />
              </div>
              <div class="advs_r">
                <div class="tit1">标志广告</div>
                <div class="tit2">
                  生成的证书清晰度很高，质量 也不错，争取把四个大师都给 收集齐。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
			<!-- <litems :List="one"></litems> -->
			<!-- <product :twos="two"></product> -->
			<footers></footers>
			<!-- <sides></sides> -->
		</div>
	</div>
</template>
<script>
	import request from "../utils/request.js";
	import headers from "../assembly/head.vue"; //头部
	import footers from "@/assembly/footer"; //页脚
	import html2canvas from "html2canvas";
	// import sides from "@/assembly/side"; //侧边
	export default {
		name: "",
		data() {
			return {
				
				one: {},
				two: {},
				name: "",
				imgurl: [],
				current: 0,
				art: 0,
				zhn_list: {},
				zhn_img_list: {},
				zh_img: "",
				user_type: "管理大师",
			};
		},
		props: [],
		components: {
			headers,
			// bananer,
			// litems,
			// product,
			footers,
			// html2canvas,
			// sides,
		},
		computed: {},
		filters: {},
		methods: {
			async common() {
				let options = {
					url: "/index/common",
					type: "post",
				};
				try {
					let res = await request(options);
					console.log(res);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					this.zh_img = res.data.data.zs_bg_img;
				} catch (error) {
					this.$message.error("请求数据失败");
				}
			},
			//获取展示证书区域
			async zhanshi() {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 42,
					},
				};
				try {
					let res = await request(options);
					console.log(res);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					this.zhn_list = res.data.data;
					this.zhn_img_list = res.data.data.content[0].images;
				} catch (error) {
					this.$message.error("请求数据失败");
				}
			},
			pai(it, i) {
				this.zhn_img_list = it.images;
				this.current = i;
			},
			//生成图片
			download() {
				const ref = this.$refs.content; // 截图区域
				html2canvas(ref, {
					backgroundColor: "#e8f4ff",
					useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
				}).then((canvas) => {
					console.log(canvas);
					const dataURL = canvas.toDataURL("image/png");
					const creatDom = document.createElement("a");
					document.body.appendChild(creatDom);
					creatDom.href = dataURL;
					console.log(creatDom.href); //图片url
					this.imgurl.push(creatDom.href);
					console.log(this.imgurl);
					creatDom.download = "图片";
					creatDom.click();
				});
			},

			async server() {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 1,
					},
				};
				try {
					let res = await request(options);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					// console.log(res.data);
					this.one = res.data.data.child[0];
					// console.log(this.one);
					this.two = res.data.data.child[1];
				} catch (error) {
					console.log(error);
					this.$message.error("请求数据失败");
				}
			},
		},
		created() {
			this.zhanshi();
			this.common();
		},
		mounted() {
			let _this = this;
			// 页面渲染结束再执行动画
			this.$nextTick(function() {
				new _this.WOW({
					live: false
				}).init();
			});
		},
	};
</script>

<style lang="less">
.wus{
	width: 1200px;
	margin: 0 auto;
}
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	//@import url(); 引入公共css类
	.contant {
		position: relative;

		.top {
			width: 100%;
			// position: fixed;
			top: 0;
			position: sticky;
			z-index: 10000;
		}
	}
</style>
<style lang="less" scoped>
	

	/deep/.el-image-viewer__wrapper {
		z-index: 100000 !important;
	}

	.xuan {
		// background: rgba(119,99,96,1);
		// width: 17px;
		// height: 15px;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 15px solid #776360;
		width: 0;
		height: 0;
		position: absolute;
		top: -17px;
		left: 45%;
	}

	.teacher {
		display: flex;
		align-items: center;
		margin-top: 26px;
		margin-bottom: 60px;

		.a {
			background-image: url("../assets/image/14.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.b {
			background-image: url("../assets/image/15.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.c {
			background-image: url("../assets/image/16.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.d {
			background-image: url("../assets/image/17.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.stu {
			position: relative;
			flex: 1;
			margin-right: 20px;
			border: 2px solid rgba(119, 99, 96, 0.6);
			// background: linear-gradient(180deg, #f5f7fa 0%, #ffffff 100%);
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			padding-left: 30px;
			padding-top: 30px;
			padding-bottom: 30px;

			&:last-child {
				margin-right: 0;
			}

			.gu {
				font-size: 26px;
				font-weight: bold;
				color: #776360;
			}

			.tit {
				font-size: 16px;
				font-weight: 400;
				color: #252121;
			}
		}
	}

	.certificate {
		background-image: url("../assets/image/8.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;

		.book {
			padding-top: 60px;
			padding-bottom: 180px;

			.zu {
				display: flex;
				align-items: center;
				justify-content: center;

				.line {
					width: 120px;
					height: 2px;
					background: #776360;
					opacity: 1;
				}

				.tit {
					font-size: 40px;
					font-weight: bold;
					line-height: 68px;
					color: #776360;
					letter-spacing: 50px;
					opacity: 1;
					margin: 0 30px;
					padding-left: 40px;
				}
			}

			.chi {
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				color: #776360;
			}

			.trophy {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 60px;

				.trophy_l {
					display: flex;

					.tit {
						width: 80px;
						height: 40px;
						background: #e2dfde;
						opacity: 1;
						border-radius: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 20px;
						font-size: 20px;
						font-weight: 400;
						color: #252121;
					}

					.tits {
						background-color: #776360 !important;
						color: #fff !important;
					}
				}

				.trophy_r {
					font-size: 20px;
					font-weight: 400;
					line-height: 34px;
					color: #252121;
				}
			}

			.books_s {
				display: flex;
				margin-top: 30px;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}

				.img_a {
					// flex: 1;

					height: 314px;
					width: 224px;
				}
			}
		}

		.imshan {
			position: absolute;
			left: 0;
			width: 530px;
			bottom: 0;
		}
	}

	.user {
		background-color: #f5f5f5;
		width: 100%;

		.users {
			padding: 60px 0px;

			.zu {
				display: flex;
				align-items: center;
				justify-content: center;

				.line {
					width: 120px;
					height: 2px;
					background: #776360;
					opacity: 1;
				}

				.tit {
					font-size: 40px;
					font-weight: bold;
					line-height: 68px;
					color: #776360;
					letter-spacing: 50px;
					opacity: 1;
					margin: 0 30px;
					padding-left: 40px;
				}
			}

			.chi {
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				color: #776360;
			}

			.adv {
				margin-top: 39px;
				display: flex;
				align-items: center;

				.advs {
					flex: 1;
					margin-right: 20px;
					padding: 30px;
					background-color: #fff;
					display: flex;
					align-items: center;

					.advs_l {
						padding: 23px 8px;

						img {
							width: 203px;
							height: 95px;
						}
					}

					.advs_r {
						.tit1 {
							font-size: 20px;
							font-weight: bold;
							line-height: 41px;
							color: #252121;
						}

						.tit2 {
							font-size: 16px;
							width: 260px;
							font-weight: 400;

							color: #252121;
							opacity: 1;
						}
					}

					&:nth-child(2n) {
						margin-right: 0;
					}
				}
			}
		}
	}

	.header1 {
		width: 100%;
		background-image: url("../assets/image/12.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-bottom: 20px;
		position: relative;
		width: 1200px;
		// height: 550px;
		margin: 0 auto;

		.he1_content {
			display: flex;

			.left_div {
				width: 50%;
				padding-top: 100px;

				h3 {
					font-size: 50px;
					font-weight: bold;
					line-height: 85px;
					color: #252121;
				}

				.left_title {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid #fff;
					box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
					// position: relative;
					margin-top: 50px;
					padding: 5px;

					.ti_input {
						// width: 100%;
						height: 73px;
						background: #fff;
						border: 1px solid #fff;
						box-sizing: border-box;
						border: 0 !important;
						border: none !important;
					}

					.left_anniu {
						cursor: pointer;
						background: linear-gradient(221deg, #b5aaa9 0%, #776360 100%);
						box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

						color: #252121;
						font-size: 20px;
						font-weight: 400;
						color: #ffffff;
						padding: 27px 30px;
						border-left: 1px solid #ececec;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 20px;
							margin-left: 10px;
						}
					}
				}

				.left_zhizuo {
					margin-top: 20px;

					.zi {
						font-size: 20px;
						font-weight: 400;
						line-height: 34px;
						color: #7c7a7a;
					}

					.cishu {
						font-size: 20px;
						font-weight: bold;
						line-height: 34px;
						color: #776360 !important;
						opacity: 1 !important;
					}
				}

				.left_jilu {
					padding-top: 60px;

					.jilu_title {
						font-size: 20px;
						font-weight: 400;
						line-height: 34px;
						color: #252121;
						opacity: 0.6;
					}

					.jilu_flex {
						background-color: #f0eeee;
						padding: 20px 25px;
						margin-top: 20px;
						// display: inline-block;
						display: flex;
            
            .jilu_flexs{
            display: flex;
            flex-wrap: wrap;
            }
						.j_img {
							width: 90px;
							height: 126px;
							background: rgba(0, 0, 0, 0);
							box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
							opacity: 1;
							margin-right: 30px;

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				input:focus {
					outline: none;
				}

				input {
					font-size: 20px;
					color: #000;
					padding-left: 20px;
				}

				input::-webkit-input-placeholder {
					font-size: 20px;
					font-weight: 400;
					line-height: 34px;
					color: #252121;
					opacity: 0.4;
					// padding-left: 20px;
				}
			}

			.right_div {
				margin-left: 110px;
				background: url("../assets/image/zsbj.png") no-repeat;
				width: 407px;
				height: 407px;
				background-size: 100% 100%;
				position: relative;

				.ght_img {
					position: absolute;
					top: 100px;
					left: 10%;
					z-index: 8;

					.xingming {
						position: absolute;
						top: 180px;
						left: 12%;
						z-index: 9;
						width: 55px;
						text-align: center;
						font-size: 12px;
					}

					.xingming_s {
						position: absolute;
						top: 230px;
						left: 105px;
						z-index: 9;
						width: 55px;
						text-align: center;
						font-size: 12px;
						// zoom: 0.9;
						font-weight: bold;
					}

					.div_img {
						width: 330px;
						height: 454px;
					}
				}
			}
		}
	}
</style>
